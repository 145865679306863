.fixed-whatsapp-icon {
  position: fixed;
  bottom: 130px;
  left: 94.6%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #25d366;
  color: var(--white-color);
  font-size: 1.7rem;
  z-index: 9;
}

@media (max-width: 568px) {
  .fixed-whatsapp-icon {
    left: 82% !important;
    bottom: 145px;
  }
}