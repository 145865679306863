.home-about-box img {
  height: 340px;
}

.common-btn {
  /* border: 1px solid #000000 !important; */
  border-radius: 25px;
  padding: 0.5rem 1.1rem !important;
  border: none;
}

.about-bg-color {
  background: var(--timber-bg);
}

.about-section {
  width: 100%;
  height: 100vh;
  position: relative;
}
.about-section img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}
@media (max-width: 4800px) and (min-width:1550px) {
  .about-section,
  .about-section img{
    position: static;
    height: auto;
  }
}
@media (max-width: 1480px) and (min-width:1100px) {
  .about-section {
    height: 80vh;
  }
}
@media (max-width: 568px) {
  .about-section img,
  .about-section {
    aspect-ratio: 1/1;
    height: auto;
  }
}
