.fs-smaall {
  font-size: .8rem !important;
  color: #000 !important;
}

.invoice-page-card {
  display: flex;
  gap: 10px;
  padding: 1rem 0;
  border-bottom: 1px solid #e3e3e3;
}

.invoice-page-card:last-child {
  border-bottom: 0;
}

.invoice-page-card .image {
  width: 100px;
}

.invoice-page-card .image img {
  border-radius: 5px;
}

.invoice-page-card .content span.d-block {
  font-size: 0.8rem;
}

.total-amt-box {
  border: 1px solid #e3e3e3;
  padding: 10px;
  border-radius: 5px;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.total-amt-box span {
  font-size: 0.8rem;
}

.total-amt-box>div {
  display: flex;
  flex-direction: column;
}

.total-amt-box p {
  margin-bottom: 0;
  color: #000 !important;
  font-weight: bold;
}

.invoice-btn-box .default-btn-one {
  background: var(--theme-color) !important;
  color: var(--white-color) !important;
  border-radius: 20px;
  font-size: 18px;
  padding: 0.4rem 1.6rem;
}