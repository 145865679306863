.product-card {
  height: 100%;
  /* border-bottom: 2px solid var(--theme-color); */
  /* border-radius: 5px; */
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.product-card .content {
  padding: 10px 5px;
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
  flex-direction: column;
  border-radius: 5px;
  padding-bottom: 15px;
}

.product-card img {
  /* border-radius: 5px 5px 0 0; */
  aspect-ratio: 1/1;
  /* border: 1px solid var(--subheading-color); */
}

.product-card .content span {
  font-size: 0.8rem;
  color: #636363;
  font-weight: 500;
}

.product-card .content a {
  padding: 5px 10px;
}

.product-card .content p {
  margin-bottom: 0;
  margin-top: -5px;
  font-size: 13px;
}

.product-card .content p a {
  font-weight: 500;
  color: var(--black-color);
}

.product-card .content .price {
  font-size: 16px;
}

.product-card .content .price del {
  color: #928f8f;
  /* margin-left: 5px; */
  font-size: 13px;
}


/* ****** Kaawaii card ***** */
/* .productimgbg{
  background: var(--card-background);
} */
.home-quantity-btn {
  font-size: 14px;
}

.sale-icon {
  top: 25px;
}

.sale-icon img {
  width: 50px;
}

.desk-add-btn {
  font-size: 1rem !important;
}