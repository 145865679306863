.review-card {
  display: flex;
}
.review-card .content {
  padding: 0 1rem;
  width: 65%;
}
.review-card .img {
  width: 35%;
}
.review-card img {
  aspect-ratio: 1/1;
  /* height: 100% !important; */
  object-fit: contain;
}
.review-card .content p:first-child {
  font-size: 1.2rem;
  font-weight: 500;
}

.review-card .content ul {
  display: flex;
  padding: 0;
}
.review-card .content ul li svg {
  color: #ffd700;
}
.review-card .content p {
  margin-bottom: 0;
}
.review-card .content .feedback {
  height: 100px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-right: 10px;
}
.review-card .content .feedback::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f3f3f3;
  height: 3px;
}

.review-card .content .feedback::-webkit-scrollbar {
  width: 2px;
  background-color: #f3f3f3;
  height: 3px;
}

.review-card .content .feedback::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--black-color);
  height: 3px;
}

.testimonial-section {
  width: 100%;
  height: 120vh;
  position: relative;
}
.testimonial-section img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}
.testimonial-section .testimonial-video {
  padding: 0.5rem;
  background: var(--white-color);
  padding-bottom: 2em;
  position: relative;
}
.testimonial-section .testimonial-video video {
  width: 100%;
}
.testimonial-section .testimonial-video p {
  position: absolute;
  left: 50%;
  bottom: -10px;
  font-family: "Molleat" !important;
  font-size: 1.8rem !important;
  transform: translateX(-50%);
}
@media (max-width: 568px) {
  .testimonial-section img,
  .testimonial-section {
    /* aspect-ratio: 1/1; */
    height: auto;
  }
  .testimonial-section img {
    position: relative;
    padding: calc(1.5rem * 0.5) 0;
    padding-bottom: 0;
  }
  .testimonial-section .container {
    position: absolute;
    top: 0;
    left: 0;
    /* margin-top: 3rem; */
  }
  .testimonial-section .testimonial-video {
    margin-top: -12rem;
  }
}
.testimonial-section .testimonial-card {
  /* text-align: center; */
  color: var(--white-color);
}
.testimonial-section .testimonial-card p,
.testimonial-section .testimonial-card span {
  color: var(--white-color) !important;
  font-size: 1.3rem !important;
  /* text-align: center !important; */
}
.testimonial-section .mySwiper .swiper-pagination-bullet,
.testimonial-section .mySwiper .swiper-pagination-bullet-active {
  background: var(--white-color) !important;
}
@media (max-width: 4800px) and (min-width: 1550px) {
  .testimonial-section {
    /* height: auto; */
  }
  .testimonial-section img {
    position: static;
    width: 100%;
  }
  .testimonial-section .col-lg-6 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .testimonial-card p {
    font-size: 1rem;
  }
}

@media (max-width: 1480px) and (min-width: 1100px) {
  .testimonial-section {
    height: 120vh;
  }
}
@media (max-width: 568px) {
  .testimonial-section {
    height: auto;
    aspect-ratio: inherit;
    margin-bottom: 1rem;
  }
  .review-card {
    flex-direction: column;
  }
  .testimonial-section .testimonial-card,
  .testimonial-section .testimonial-card p {
    text-align: center !important;
  }
  .review-card .img,
  .review-card .content {
    width: 100%;
    padding: 0;
  }
  .review-card .content p:first-child {
    margin-top: 10px;
  }
  .review-card .content .feedback {
    height: auto;
  }
  .testimonial-card p {
    font-size: 0.8rem !important;
    line-height: 1.4;
    padding: 0 2rem;
    margin-bottom: 5px;
  }
  .testimonial-card span {
    margin-bottom: 10px;
    display: inline-block;
  }
  .testimonial-section .testimonial-card p,
  .testimonial-section .testimonial-card span {
    font-size: 1rem !important;
  }
}

@media (max-width: 380px) {
  .testimonial-section .testimonial-video {
    margin-top: -10rem;
  }
}
