.product-section {
  width: 100%;
  height: 100vh;
  position: relative;
}
.product-section img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}
.product-section .four-product-card {
  margin-top: calc(0.5rem * 0.5);
}
.product-section .four-product-card .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: calc(1.5rem * 0.5) 0;
  padding-top: calc(0.8rem * 0.5);
}
.product-section .four-product-card .price {
  /* font-family: "Molleat" !important; */
  font-size: 1.5rem !important;
  color: var(--black-color) !important;
}
.product-section .four-product-card .price svg{
  margin-top: -2px;
}
.product-section .four-product-card .des {
  font-size: 0.9rem !important;
  line-height: normal;
}
.product-section-skeleton > .ant-skeleton{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.product-section-skeleton .ant-skeleton .ant-skeleton-image{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.product-section-skeleton .content .aspect-ratio{
aspect-ratio: 1/1;
}
@media (max-width: 4800px) and (min-width: 1550px) {
  .product-section {
    padding: 0;
  }
  .product-section {
    height: auto;
  }
  .product-section img {
    position: static;
    width: 100%;
    height: auto;
  }
}
@media (max-width: 1480px) and (min-width: 1100px) {
  .product-section {
    padding: 0;
    height: auto;
  }
  .product-section img {
    position: static;
    width: 100%;
    height: auto;
  }
}
@media (max-width: 992px) {
  .product-section img,
  .product-section {
    aspect-ratio: 1/1;
    height: auto;
  }
}