.videos-section-box {
  display: flex;
  /* flex-wrap: wrap; */
  overflow-x: auto;
  height: auto;
}
.videos-section-box > div {
  width: 20%;
  padding-right: 15px;
}
.videos-section-box > div video {
  width: 100%;
  border-radius: 5px;
}

.video-section {
  width: 100%;
  height: 100vh;
  position: relative;
}
.video-section img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}
@media (max-width: 568px) {
  .video-section img,
  .video-section {
    aspect-ratio: 1/1;
    height: auto;
  }
}
@media (max-width: 578px) {
  .videos-section-box > div {
    max-width: 300px;
    min-width: 300px;
    padding-right: 15px;
    display: inline-block;
    padding-right: 0;
    margin-right: 15px;
  }
  .videos-section-box > div:last-child {
    margin-right: 0;
  }
}
