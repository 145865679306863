@font-face {
  font-family: Molleat;
  src: url(./assets/fonts/molleat/Molleat-Regular.otf);
}

@font-face {
  font-family: MYRIADPRO;
  src: url(./assets/fonts/Myriad-Pro/MYRIADPRO-REGULAR.OTF);
}

:root {
  --theme-color: #3ec0e3;
  --white-color: #fff;
  --black-color: #3b3b3b;
  --subheading-color: #000000;
  --top-bar-bg: #0e0e0e;
  --top-bar-content: #ffffff;
  --heading-color: #3ec0e3;
  --content-color: #3b3b3bb5;
  --card-background: #f6f6f6;
  --timber-bg: #ffc734;
  --flower-bg: #f8c5d8;
  --orange-bg: #faac85;
  --aqua-bg: #abd7f0;
}

body {
  font-family: "Lato", sans-serif;
}

h2 {
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  color: var(--heading-color);
}

h3 {
  color: var(--subheading-color);
  font-size: 3rem;
  font-weight: 400;
  font-style: normal;
  text-transform: capitalize;
  letter-spacing: 0.2rem;
}

h4 {
  color: var(--subheading-color);
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
}

h5 {
  color: var(--subheading-color);
  font-family: "Poppins", sans-serif !important;
  font-size: 20px;
  font-weight: 500;
}

p {
  font-size: 18px !important;
  font-family: MYRIADPRO !important;
  color: var(--content-color) !important;
  font-weight: 300;
  text-align: justify;
  line-height: 1.8rem;
}

.main-subheading {
  font-family: Molleat;
  font-style: italic;
  font-size: 3rem !important;
}

.footer-short-dexcription {
  text-align: justify;
}

@media (max-width: 900px) and (min-width: 200px) {
  h3 {
    color: var(--subheading-color);
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
    text-transform: capitalize;
  }
}

a:hover {
  /* color: var(--black-color) !important; */
}

p {
  color: var(--black-color);
}

ul {
  list-style: none;
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
  color: var(--black-color);
}

.nav-heading {
  font-size: 2.3rem;
  /* font-family: Navheading; */
  font-family: "Pinyon Script";

  /* font-family: "Cinzel Decorative", serif; */
}

/* .nav-heading span{
  font-family: Navheading;
} */
.theme-btn {
  /* padding: 10px 15px; */
  padding: 0.25rem 0.5rem;
  background: var(--theme-color);
  color: var(--white-color);
  display: inline-block;
  border-radius: 5px;
  cursor: pointer;
}

.theme-btn-disabled {
  background: var(--theme-color);
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1.1rem;
  opacity: 0.5;
  cursor: not-allowed;
  color: var(--white-color) !important;
}

.main-heading {
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 400;
  word-spacing: 0.1rem;
  letter-spacing: 0.2rem;
  /* font-family: Molleat; */
  font-family: "Poppins", sans-serif;
  padding-bottom: 0;
  margin-bottom: 0;
}

.sub-heading {
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: 400;
  word-spacing: 0.1rem;
  letter-spacing: 0.2rem;
  /* font-family: Molleat; */
  font-family: "Poppins", sans-serif;
  padding-bottom: 0;
  margin-bottom: 0;
}

.section-spacing-x {
  padding: 0 10px;
}

.aspect-ratio {
  aspect-ratio: 1/1;
}

@media (max-width: 568px) {
  .section-spacing-x-mobile {
    padding: 0;
  }

  .nav-heading {
    font-size: 1rem;
    margin-left: 0px !important;
    padding-left: 9px !important;
  }

  .main-heading {
    margin-bottom: 1rem;
    font-size: calc(1.325rem + 0.9vw);
    font-family: "Poppins", sans-serif;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 900px) and (min-width: 200px) {
  .main-heading {
    font-size: 1.6rem;
    font-family: "Poppins", sans-serif;
  }
}

.quantity .quantity-btn button {
  border: none;
  /* background: transparent; */
  background: var(--theme-color);
  color: var(--black-color) !important;
}

.count-value {
  width: 40px;
  text-align: center;
  border: none;
}

.blank-section {
  min-height: 60vh;
}

.breadcrumb .breadcrumb-item {
  font-size: 0.9rem;
}

/* swiper dots */
.home-banner-slider .swiper-pagination-bullet-active {
  background: var(--theme-color) !important;
}

.customer-slider-dots .swiper-pagination-bullet-active {
  background: var(--black-color) !important;
}

.home-banner-slider .swiper-pagination-bullet {
  width: var(--swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 10px));
  height: var(--swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 10px));
  display: inline-block;
  border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
  background: var(--swiper-pagination-bullet-inactive-color, #fff);
  opacity: var(--swiper-pagination-bullet-inactive-opacity, 1) !important;
}

@media (max-width: 500px) {
  .home-banner-slider .swiper-pagination-bullet {
    width: var(--swiper-pagination-bullet-width,
        var(--swiper-pagination-bullet-size, 8px));
    height: var(--swiper-pagination-bullet-height,
        var(--swiper-pagination-bullet-size, 8px));
    display: inline-block;
    border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
    background: var(--swiper-pagination-bullet-inactive-color, #fff);
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 1) !important;
  }
}

.social-icon-bullet {
  align-items: center !important;
}

.social-icon-bullet li {
  list-style: none !important;
  margin-right: 10px;
}

.date-picker-edit-profile :where(.css-dev-only-do-not-override-mzwlov).ant-picker-outlined {
  background: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-color: #dee2e6 !important;
}

.nodata-icon {
  width: 100px;
  height: 100px;
}

.payment-box {
  margin-top: -5rem;
}

.payment-box .icon {
  width: 200px;
  height: 200px;
  font-size: 8rem;
  padding: 5px;
  background-color: #f5f3f0;
  border-radius: 50%;
  transition: 0.4s all;
  color: var(--theme-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2rem;
}

@media (max-width: 992px) {
  .social-icon-bullet {
    align-items: flex-start !important;
  }

  .add-btn-mobile {
    font-size: 0.8rem;
  }
}

/* *** Coupon Code **** */
.coupon-card {
  border: 1px solid var(--theme-color);
  border-top: 4px solid var(--theme-color);
  border-radius: 5px;
  padding: 0 !important;
}

.coupon-card-code {
  width: fit-content;
  background: var(--theme-color);
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff !important;
}

.coupon-main-box .apply-coupon-btn {
  height: auto !important;
  background: var(--theme-color) !important;
  border: none;
  border-top: 1px solid #f3f3f3;
  color: var(--theme-color) !important;
  border-radius: 0 0 5px 5px;
  padding: 0 !important;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (max-width: 300px) {
  .quantity .quantity-btn button {
    font-size: 10px !important;
  }
}

/* *** Qr Scanner *** */
.Clientimg {
  bottom: 0;
  width: 100%;
  text-align: center;
  transform: translateY(150px);
}

.Clientimg img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
}

.contact-details {
  margin-top: 155px;
}

.qricon {
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background: var(--theme-color);
  font-size: 1.6rem !important;
  margin-right: 0.8rem !important;
}

.qrbg-style {
  height: 300px;
}

.qrbg-style img {
  object-fit: cover;
}

@media (min-width: 500px) {
  .fix-size {
    width: 500px;
    border: 1px solid #8080801c;
  }
}

.qr-font {
  font-size: 20px;
}

@media (max-width: 992px) {
  .mobile-imgae-space {
    padding: calc(1.5rem * 0.5) !important;
    padding-top: 0 !important;
  }
}

@media (max-width: 500px) {
  .qr-btn-position {
    position: fixed;
    bottom: 0 !important;
  }

  .go-to-top {
    /* bottom: 140px !important; */
  }
}

.location-qr {
  font-size: 19px !important;
}

@media (min-width: 790px) {
  .footer-bottom-mobile {
    display: none !important;
  }

  .nav-desk-position {
    position: fixed;
  }
}

/* @media (max-width:790px) {
  .footer-bottom-mobile {
    background: var(--theme-color);
  }

  .desktop-top-link,
  .desktop-footer-option,
  .toggle-desk-new {
    display: none !important;
  }

  .copyright-mobile-space {
    padding-bottom: 60px;
  }

  .phone-copr-right-font {
    font-size: 15px !important;
  }

  .nav-desk-position {
    position: relative;
  }

  .homebannersize img {
    margin-top: -110px !important;
  }

  .mobile-card-content {
    border: 1px solid #8080801f;
  }
  
  .copyright-mobile-space{
    border-top: 1px solid #80808024;
    padding-top: 10px;
  }
} */

.nav-logo-inner-new {
  width: 120px !important;
}

.offcanvas-body::-webkit-scrollbar {
  width: 1px;
}

.offcanvas-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.offcanvas-body::-webkit-scrollbar-thumb {
  background: grey;
}

.offcanvas-body::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.timber-position {
  bottom: 0;
  /* top: 150px; */
}

.timber-position p {
  text-align: justify;
}

.timberbanner {
  height: 690px;
}

.timberimg {
  height: 350px;
}

@media (min-width: 790px) {
  .commomimg {
    height: 100vh !important;
  }

  .mobilebanner {
    display: none;
  }
}

@media (min-width: 1080px) {
  .commomimg .desktopbanner {
    height: 100% !important;
    object-fit: cover !important;
  }
}

@media (max-width: 1080.9px) and (min-width: 1040px) {
  .timer-main-box {
    background: var(--timber-bg);
  }

  .flower-main-box {
    background: var(--flower-bg);
  }

  .orange-main-box {
    background: var(--orange-bg);
  }

  .aqua-main-box {
    background: var(--aqua-bg);
  }

  .commomimg {
    height: 100vh !important;
    margin: auto;
    display: flex;
    align-items: center;
    overflow-x: hidden;
  }

  .commomimg .desktopbanner {
    height: 100% !important;
    object-fit: cover;
    transform: translateX(15px);
  }
}

@media (max-width: 1040.9px) and (min-width: 790px) {
  .timer-main-box {
    background: var(--timber-bg);
  }

  .flower-main-box {
    background: var(--flower-bg);
  }

  .orange-main-box {
    background: var(--orange-bg);
  }

  .aqua-main-box {
    background: var(--aqua-bg);
  }

  .commomimg {
    height: 100vh !important;
    margin: auto;
    display: flex;
    align-items: center;
  }

  .commomimg .desktopbanner {
    height: 100% !important;
  }
}

@media (max-width: 790px) {
  .timer-main-box {
    background: var(--timber-bg);
  }

  .flower-main-box {
    background: var(--flower-bg);
  }

  .orange-main-box {
    background: var(--orange-bg);
  }

  .aqua-main-box {
    background: var(--aqua-bg);
  }

  .commomimg {
    margin: auto;
    display: flex;
    align-items: center;
  }

  .commomimg .desktopbanner {
    height: 100% !important;
    object-fit: contain;
  }

  .desktopbanner {
    display: none;
  }

  .mobilebannersize {
    height: 100%;
    width: 100%;
  }
}

.Add-cart-main-box {
  bottom: 2rem;
}

.timber-add-btn,
.timber-add-btn:hover {
  background: var(--timber-bg) !important;
  color: var(--white-color) !important;
  border-radius: 20px;
  font-size: 20px;
  padding: 0.4rem 1.6rem;
  margin-left: -60px;
  border: 3px dotted var(--white-color) !important;
}

.flower-add-btn,
.flower-add-btn:hover {
  background: var(--flower-bg) !important;
  color: var(--white-color) !important;
  border-radius: 20px;
  font-size: 20px;
  padding: 0.4rem 1.6rem;
  margin-left: -60px;
  border: 3px dotted var(--white-color) !important;
}

.orange-add-btn,
.orange-add-btn:hover {
  background: var(--orange-bg) !important;
  color: var(--white-color) !important;
  border-radius: 20px;
  font-size: 20px;
  padding: 0.4rem 1.6rem;
  margin-left: -60px;
  border: 3px dotted var(--white-color) !important;
}

.aqua-add-btn,
.aqua-add-btn:hover {
  background: var(--aqua-bg) !important;
  color: var(--white-color) !important;
  border-radius: 20px;
  font-size: 20px;
  padding: 0.4rem 1.6rem;
  margin-left: -60px;
  border: 3px dotted var(--white-color) !important;
}

.sold-out-btn {
  /* background: #fff; */
  border-radius: 20px;
  font-size: 18px;
  padding: 5px 1rem;
  cursor: not-allowed;
  width: fit-content;
  margin-bottom: 10px;
  border: 3px dotted #fff !important;
  /* color: var(--white-color); */
  background: #80808075 !important;
  color: #fff;
}

.sold-out {
  background: #80808075 !important;
}

.notiflix-loading {
  z-index: 99999 !important;
}

@media (max-width: 1080px) {

  .timber-add-btn,
  .timber-add-btn:hover {
    background: var(--timber-bg) !important;
    color: var(--white-color) !important;
    border-radius: 20px;
    font-size: 18px;
    padding: 0.4rem 1.6rem;
    margin-left: -20px;
    border: 3px dotted var(--white-color) !important;
  }

  .flower-add-btn,
  .flower-add-btn:hover {
    background: var(--flower-bg) !important;
    color: var(--white-color) !important;
    border-radius: 20px;
    font-size: 18px;
    padding: 0.4rem 1.6rem;
    margin-left: -20px;
    border: 3px dotted var(--white-color) !important;
  }

  .orange-add-btn,
  .orange-add-btn:hover {
    background: var(--orange-bg) !important;
    color: var(--white-color) !important;
    border-radius: 20px;
    font-size: 18px;
    padding: 0.4rem 1.6rem;
    margin-left: -20px;
    border: 3px dotted var(--white-color) !important;
  }

  .aqua-add-btn,
  .aqua-add-btn:hover {
    background: var(--aqua-bg) !important;
    color: var(--white-color) !important;
    border-radius: 20px;
    font-size: 18px;
    padding: 0.4rem 1.6rem;
    margin-left: -20px;
    border: 3px dotted var(--white-color) !important;
  }
}

@media (max-width: 790px) and (min-width: 580px) {
  .Add-cart-main-box {
    bottom: 2rem;
    text-align: left !important;
  }

  .orange-add-btn,
  .orange-add-btn:hover {
    background: var(--orange-bg) !important;
    color: var(--white-color) !important;
    border-radius: 20px;
    font-size: 18px;
    padding: 0.4rem 1.6rem;
    margin-left: -20px;
    border: 3px dotted var(--white-color) !important;
  }

  .orange-add-btn,
  .orange-add-btn:hover {
    background: var(--orange-bg) !important;
    color: var(--white-color) !important;
    border-radius: 20px;
    font-size: 18px;
    padding: 0.4rem 1.6rem;
    margin-left: 10rem !important;
    border: 3px dotted var(--white-color) !important;
  }

  .timber-add-btn,
  .timber-add-btn:hover {
    background: var(--timber-bg) !important;
    color: var(--white-color) !important;
    border-radius: 20px;
    font-size: 18px;
    padding: 0.4rem 1.6rem;
    margin-left: 10rem !important;
    border: 3px dotted var(--white-color) !important;
  }

  .flower-add-btn,
  .flower-add-btn:hover {
    background: var(--flower-bg) !important;
    color: var(--white-color) !important;
    border-radius: 20px;
    font-size: 18px;
    padding: 0.4rem 1rem;
    margin-left: 10rem !important;
    border: 3px dotted var(--white-color) !important;
  }

  .aqua-add-btn,
  .aqua-add-btn:hover {
    background: var(--aqua-bg) !important;
    color: var(--white-color) !important;
    border-radius: 20px;
    font-size: 18px;
    padding: 0.4rem 1.6rem;
    margin-left: 10rem !important;
    border: 3px dotted var(--white-color) !important;
  }
}

@media (max-width: 580px) {
  .default-btn-one {
    padding: 5px 1rem;
  }
}