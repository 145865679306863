.nav-logo {
  /* width: 100px; */

  width: 120px;
  /* object-fit: contain;
  background: var(--white-color);
  border-radius: 50%;
  height: 120px;
  padding: 10px; */
}

.main-navbar .navbar-toggler {
  border: none !important;
  color: var(--theme-color);
  padding: 0;
  font-size: 1.5rem !important;
}

button:focus-visible,
button:focus {
  border: none !important;
  box-shadow: none !important;
}

.main-nav-right-links {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav-right-links li:first-child {
  margin-bottom: -2px;
}

.main-nav-right-links li {
  font-size: 1.5rem;
  /* margin-right: 1rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-nav-right-links li:last-child {
  margin: 0;
}

.main-nav-right-links li span {
  font-size: 1rem;
  background: var(--theme-color);
  color: var(--white-color);
  border-radius: 5px;
  /* padding: 4px 8px; */
  margin-left: 10px;
  /* width: 30px; */
  text-align: center;
}

.main-nav-right-links li svg {
  /* color: var(--theme-color); */
  color: var(--black-color);
}

.main-nav-right-links li.cursor-pointer {
}

/* Main Navbar Menu */
.main-nav-menu-links a {
  /* color: var(--black-color); */
  color: var(--white-color);
  font-weight: 500;
}

.main-nav-menu-links > li {
  text-align: center;
  padding: 0 10px;
}

.fixed-nammenu {
  background: var(--white-color);
}

.main-nav-menu-links a {
  font-size: 1.1rem;
  position: relative;
  text-transform: capitalize;
  display: inline-block;
}

/* .main-nav-menu-links a::before{
    content: "\f0d7";
    position: absolute;
    font-family: "FontAwesome";
    color: var(--theme-color);
    top: 0;
    left: 50%;
    transform: translate(-50%,-50px);
    transition: .3s;
    opacity: 0;
} */
.main-nav-menu-links a:hover::before {
  opacity: 1;
  transform: translate(-50%, -15px);
  z-index: 999;
}

.main-nav-menu-sm-links li a {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 1.5rem;
  margin-right: 10px;
}

/* .main-nav-menu-sm-links li:last-child a {
  margin: 0;
} */
.main-nav-menu-sm-links li a {
  font-size: 1.35rem;
  padding: 5px;
  background-color: var(--theme-color);
  border-radius: 50%;
}

.main-nav-menu-sm-links li a svg {
  color: var(--white-color);
}

/* .main-nav-menu-links li .dropdown-toggle::after{
    content: "\f107";
    font-family: "FontAwsome";
    border: none;
} */
.footer-bottom-mobile svg {
  color: var(--theme-color);
}

.login-icon svg {
  font-size: 1.5rem;
}

.login-icon {
  padding: 0 !important;
  border: none !important;
  background: transparent !important;
}

.login-dropdown .dropdown-menu.show {
  left: auto !important;
  right: 0 !important;
  /* transform: translate3d(-140px, 0px, 0px) !important; */
}

.dropdown-item.active,
.dropdown-item:active {
  background: transparent !important;
  color: var(--black-color) !important;
}

.dropdown-toggle:hover {
  border: none !important;
  background: transparent !important;
}

.login-dropdown .dropdown-toggle {
  margin-right: 10px;
}

.login-dropdown .dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle::after {
  border: none !important;
  /* font: 'fontawesome';
    content: "\f107"; */
  content: "\f107" !important;
  font-family: FontAwesome;
  vertical-align: 0 !important;
  font-weight: bold;
}

.main-nav-right-links.contact-links {
  align-items: center;
}

.offcanvas-menu-color .dropdown-toggle.nav-link {
  padding-left: 0;
}

.offcanvas-menu-color .dropdown-menu {
  position: relative !important;
  transform: translate3d(0px, 0px, 0px) !important;
  inset: 0 !important;
  border: none !important;
}

.offcanvas-menu-color .dropdown-menu .dropdown-item {
  padding-left: 8px !important;
}

.offcanvas-menu-color .dropdown-menu .dropdown-item:focus,
.offcanvas-menu-color .dropdown-menu .dropdown-item:hover {
  background: var(--white-color) !important;
}

.main-nav-right-links.contact-links li > a {
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: var(--theme-color);
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-nav-right-links.contact-links li:last-child > a {
  margin-right: 0;
}

.main-nav-right-links.contact-links li a svg {
  color: var(--white-color);
  font-size: 1.1rem;
}

.desk-fixed-icons {
  /* position: fixed; */
  /* left: 10px; */
  /* bottom: 40px; */
  background: var(--theme-color);
  z-index: 999;
  padding: 7px 14px;
  justify-content: center !important;
  border-radius: 20px;
  /* gap: 0 0.5rem; */
}

.desk-fixed-icons svg {
  font-size: 1.3rem;
  color: var(--white-color) !important;
}

.toggle-desk-new {
  background: var(--white-color) !important;
  color: var(--theme-color) !important;
  border-radius: 50%;
  padding: 0;
  height: 35px;
  width: 35px;
  font-size: 1.3rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-desk-new:hover {
  background: var(--white-color) !important;
}

.login-dropdown svg {
  background-color: var(--white-color) !important;
  border-radius: 50% !important;
  color: var(--theme-color) !important;
  padding: 7px;
  font-size: 2.2rem;
}

.home-bg .navbar-brand {
  display: none !important;
}

.inner-page-nav .navbar-brand {
  display: block;
}

.inner-page-nav {
  position: absolute !important;
  z-index: 999;
}

.mobile-navbar {
  display: none;
}

@media (min-width: 992px) {
  .dropdown:hover .dropdown-menu {
    /* margin-top: -7px !important; */
  }
}

@media (max-width: 1430px) {
  .main-nav-menu-links a {
    font-size: 0.9rem;
  }
}

@media (max-width: 992px) {
  .main-navbar,
  .home-fixed-navbar {
    display: none;
  }

  .fixed-nammenu .border-top.border-bottom {
    border-top: none !important;
  }

  .navbar-brand {
    margin-left: 0;
    margin-right: 0 !important;
  }

  .offcanvas-body .dropdown-menu.show {
    border: none !important;
    padding: 0;
    margin: 0;
  }

  .main-navbar-offcanvas {
    max-width: 80% !important;
  }

  .main-navbar-offcanvas .contact-links {
    align-items: center;
  }

  .main-navbar-offcanvas .contact-links svg {
    font-size: 1.7rem;
    padding: 5px;
    background-color: var(--theme-color);
    color: var(--white-color);
    border-radius: 50%;
    margin-right: 10px;
  }
}

@media (max-width: 568px) {
  .nav-logo {
    width: 70px;
  }

  .navbar-brand {
    margin-left: -1rem !important;
  }
}

@media (max-width: 400px) {
  .navbar-brand {
    margin-left: -1rem !important;
  }
}

.toggle-btn-offcanvas {
  background: transparent;
  border: none;
}

/* .offcanvas-toggle-new {
  font-size: 30px;
  color: var(--theme-color);
} */
.offcanvas-menu-color a {
  color: var(--black-color);
  font-size: 18px;
}

.offcanvas-follow-content {
  color: var(--black-color);
  font-size: 18px;
}

/* .toggle-btn-offcanvas:hover {
  background: transparent !important;
} */

.blurbg {
  height: 100vh;
  object-fit: cover;
  z-index: 9999 !important;
}

.home-bg {
  background: transparent;
  z-index: 999 !important;
}

.other-bg {
  background: var(--theme-color);
  z-index: 9999 !important;
}

.nav-box-inner-banner {
  top: 0;
  left: 0;
  position: absolute;
}

.home-navbar {
  position: fixed;
  transition: 1s;
  top: -100%;
  left: 0;
  width: 100%;
  opacity: 0;
}

.home-fixed-navbar {
  top: 0;
  opacity: 1;
  z-index: 9999;
}

.home-fixed-navbar .navbar {
  background: var(--white-color) !important;
}

@media (max-width: 450px) {
  .blurbg {
    height: 100vh;
    object-fit: cover;
    z-index: 9999 !important;
    width: 300px !important;
  }
}
