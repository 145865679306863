.card-layout-cahane-box ul{
    /* list-style: disc; */
    /* display: flex; */
    columns: 3 5px;
    padding: 0;
    margin-right: 1.5rem;
    cursor: pointer;
    column-gap: 5px;
    padding-top: 5px;
}
.card-layout-cahane-box ul:last-child{
    columns: 4 5px;
}
.card-layout-cahane-box ul li{
    width: 5px;
    height: 5px;
    background: var(--black-color);
    border-radius: 50%;
    margin-bottom: 5px;
}
.product-listing-skeleton .product-box .ant-skeleton-image{
    aspect-ratio: 1/1;
    height: auto;
}