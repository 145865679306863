.bags-section {
  width: 100%;
  height: 100vh;
  position: relative;
}

.bags-section > img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.bags-section .bag-product-card {
  background-color: var(--white-color);
  padding: 10px;
}

.bags-section .bag-product-card .price {
  font-size: 1.5rem !important;
  color: var(--black-color) !important;
}

.bags-section .bag-product-card .price svg {
  margin-top: -2px;
}

.bags-section .bag-product-card .cat-name {
  font-size: 1rem !important;
}

.default-btn-bags,
.bags-section .bag-product-card .default-btn-one {
  background: var(--theme-color);
  border-radius: 20px;
  font-size: 18px;
  padding: 5px 1rem;
  border: 3px dotted var(--white-color) !important;
  color: var(--white-color);
}

.default-btn-bags:hover,
.bags-section .bag-product-card .default-btn-one:hover {
  color: var(--white-color);
  background: var(--theme-color);
}

/* @media (max-width: 4800px) and (min-width: 1550px) {
  .bags-section,
  .bags-section img {
    position: static;
    height: auto;
  }
} */
/* @media (max-width: 1480px) and (min-width: 1100px) {
  .bags-section {
    height: 80vh;
  }
} */
@media (max-width: 568px) {
  .bags-section {
    height: auto;
  }
  .bags-section > img {
    /* aspect-ratio: 1/1; */
    height: 100%;
  }

  .bags-section .product-card img {
    object-fit: cover;
    aspect-ratio: 1/1;
  }
}
