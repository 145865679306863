.product-deatail-content h1 {
  font-family: inherit;
  font-weight: 300;
}

.product-deatail-content ul {
  padding: 0;
  display: flex;
  gap: 5px;
}

.product-deatail-content .price h3 {
  font-family: inherit !important;
}

.product-deatail-content .price del {
  color: #c1c1c1;
  margin-left: 5px;
  font-size: 1rem !important;
}

.product-deatail-content .view svg {
  animation: eyeanimation;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
  margin-right: 5px;
  margin-top: -2px;
}

@keyframes eyeanimation {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}

.variants p {
  margin-top: 5px;
}

.add-btn-box {
  display: flex;
  align-items: stretch;
  text-align: center;
  align-items: center;
}

.add-btn-box span {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-btn-box .quantity {
  /* border-radius: 5px; */
}

.star-review input[type="radio"] {
  display: none;
}

.over-all-rating {
  display: flex;
  justify-content: space-between;
}

.container-clear {
  text-align: right;
  cursor: pointer;
  color: var(--theme-color-dark);
}

.add-btn-box .quantity input {
  border: none;
  background: transparent;
  width: 40px;
  margin: 0 5px;
  text-align: center;
}

.add-btn-box .quantity input:focus-visible {
  outline: none;
}

@media (max-width: 450px) {
  .add-btn-box .quantity input {
    border: none;
    background: transparent;
    width: 35px !important;
    margin: 0 !important;
    text-align: center;
    font-size: 15px !important;
  }
}

@media (max-width: 331px) {
  .add-btn-box .quantity input {
    border: none;
    background: transparent;
    width: 25px !important;
    margin: 0 !important;
    text-align: center;
    font-size: 15px !important;
  }
}

@media (max-width: 300px) {
  .add-btn-box .quantity input {
    border: none;
    background: transparent;
    width: 23px !important;
    margin: 0 !important;
    text-align: center;
    font-size: 15px !important;
  }
}

@media (min-width: 993px) {
  .product-detail-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: center;
  }
}

.product-detail-tabs .ant-tabs-tab {
  font-size: 1.5rem;
  /* font-family: "Cinzel Decorative", serif; */
  font-family: "Cormorant", serif;
}

.product-detail-tabs .ant-tabs .ant-tabs-tab-btn:active,
.product-detail-tabs .ant-tabs .ant-tabs-tab:hover,
.product-detail-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--black-color) !important;
}

.product-detail-tabs .ant-tabs .ant-tabs-ink-bar {
  background: var(--black-color) !important;
}

.select-color span {
  width: 20px;
  height: 20px;
  background: rgb(144, 68, 68);
  margin-right: 0.8rem;
  border-radius: 50%;
  outline: 2px solid #dee2e6;
  outline-offset: 2px;
  cursor: pointer;
}

.select-color span:nth-child(2) {
  background: var(--theme-color);
}

.select-size span {
  width: 30px;
  height: 30px;
  border: 1px solid #dee2e6;
  margin-right: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.product-review-card {
  padding: 1rem;
  border: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
}

.product-review-card p {
  font-size: 1.1rem;
}

.product-review-card .details {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.product-review-card .details img {
  border-radius: 50%;
  margin-right: 1rem;
  width: 100px;
}

.product-review-card .details ul {
  padding: 0;
}

.product-review-card .details p {
  margin: 5px 0;
}

.ant-drawer {
  z-index: 9999;
}

.write-review-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
  font-family: "Cinzel Decorative", serif;
}

.write-review-drawer .ratings {}

.product-detail-skeleton .product-detail-img {
  aspect-ratio: 1/1;
}

.product-detail-img {
  aspect-ratio: 1/1;
  object-fit: cover;
}

.video-prev {
  position: relative;
  cursor: pointer;
}

.video-prev svg {
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--theme-color);
  font-size: 2rem;
}

@media (max-width: 568px) {
  .product-detail-tabs .ant-tabs-tab {
    font-size: calc(1.325rem + 0.9vw);
  }

  .video-prev svg {
    font-size: 1rem;
    top: 45%;
  }
}