.home-banner-slider {
  /* height: 60vh; */
  margin: 20px;
  margin-bottom: 0;
}

.home-banner-slider img {
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet-active {
  background: var(--theme-color);
}

.home-slider-skeleton {
  /* .home-slider-skeleton.ant-skeleton-image { */
  padding: 18px !important;
  /* padding-bottom: 0 !important; */
  height: 60vh !important;
  width: 100% !important;
}

.homebannersize-skeleton .w-100 {
  height: 100vh;
}

.homebannersize-skeleton .ant-skeleton-image {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

@media (max-width: 578px) {
  .home-banner-slider {
    height: auto;
    margin: 10px;
    margin-bottom: 0;
  }

  .home-slider-skeleton {
    height: 20vh !important;
  }

  .mobile-imgae-space-skeleton {
    aspect-ratio: 1/1;
    height: auto;
  }

  .mobile-imgae-space-skeleton .ant-skeleton-image {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

/* .home-slider-skeleton.ant-skeleton-image {  

} */

/* .main-slider-skeleton {
  width: 97%;
} */

/* ************************ Kawaii Banner ************************ */
.homebannersize {
  height: 100vh;
}

.homebannersize img {
  height: 100%;
  object-fit: cover;
}

@media (max-width: 4800px) and (min-width: 1520px) {
  .homebannersize {
    height: auto;
  }
}

@media (max-width: 1480px) and (min-width: 1100px) {
  .homebannersize {
    height: auto;
  }
}

@media (max-width: 992px) {

  /* offer */
  .main-nav-right-links.contact-links .desktop-top-link p {
    color: #fff !important;
    font-size: 15px !important;
    margin-bottom: 0;
  }

  .mobile-navbar {
    display: block;
  }

  .mobile-navbar .login-dropdown .dropdown-menu.show {
    left: auto !important;
  }

  .mobile-navbar .main-nav-right-links .login-icon {
    margin-right: 0 !important;
  }

  .mobile-navbar .main-nav-right-links .login-icon svg {
    background-color: var(--white-color) !important;
    border-radius: 50% !important;
    color: var(--theme-color) !important;
    padding: 5px;
    font-size: 2rem;
  }

  .mobile-navbar .cart-icon .cart-qut {
    background: transparent !important;
    color: var(--white-color) !important;
    padding: 0;
    font-size: 1.2rem;
    display: inline-block;
    margin-bottom: -1px;
  }

  .mobile-navbar .main-nav-right-links .cart-icon {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background: var(--theme-color);
    z-index: 999;
    width: 20%;
    padding: 10px;
    justify-content: center !important;
    border-radius: none;
    gap: 0 0.5rem;
    transition: 1s;
  }

  .mobile-navbar-fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    animation: navbarfixed 1s;
  }

  .homebannersize img,
  .homebannersize {
    height: auto;
  }

  .mobile-navbar {
    padding: calc(1.5rem * 0.5);
    margin-bottom: calc(1.5rem * 0.5);
    background-color: var(--theme-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-navbar>svg {
    /* position: absolute;
    top: 1.5rem;
    left: 1.5rem; */
    color: var(--white-color) !important;
    /* z-index: 9; */
    font-size: 2rem;
    /* background-color: #093d4a; */
  }

  .mobile-navbar svg {
    color: var(--white-color) !important;
  }

  .mobile-navbar .main-nav-right-links li span {
    background-color: var(--white-color) !important;
    color: var(--theme-color) !important;
  }

  .hide-cart-btn {
    bottom: -100px !important;
  }
}

@media (max-width: 568px) {
  .mobile-navbar .main-nav-right-links .cart-icon {
    width: 100%;
  }
  .mobile-navbar .main-nav-right-links .cart-icon-mobile {
    background: var(--theme-color);
    border-radius: 40px;
    bottom: 37px;
    gap: 0 0.5rem;
    justify-content: center !important;
    left: 50%;
    padding: 10px;
    position: fixed;
    transform: translateX(-50%);
    transition: 1s;
    width: 20%;
    z-index: 999;
    width: 50%;
  }
}

@keyframes navbarfixed {
  0% {
    top: -100%;
  }

  25% {
    top: -75%;
  }

  50% {
    top: -50%;
  }

  75% {
    top: -25%;
  }

  100% {
    top: 0;
  }
}

.mobile-nav-drower svg {
  background-color: var(--theme-color) !important;
  border-radius: 50% !important;
  color: var(--white-color) !important;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  /* font-size: 2rem !important; */
}