.quantity{
    width: fit-content;
    border-radius: 20px !important;
    overflow: hidden;
    border: 1px solid var(--theme-color) !important;
}
.quantity .quantity-btn button{
    background: transparent !important;
    padding: .5rem !important;
}
.quantity .quantity-delete-btn{
    padding: 0 10px;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--theme-color);
    color: var(--white-color);
    font-size: 1.2rem;
    cursor: pointer;
}
.quantity .quantity-delete-btn svg{
    margin-top: -3px;
}