.award-modal .modal-header {
    border-bottom: none;
    position: absolute;
    z-index: 999;
    top: 5px;
    right: 20px;
}

.award-modal .modal-body {
    padding: 0;
}

.award-modal .btn-close {
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
    opacity: 1;
}

.award-modal .modal-header button {
    position: absolute;
}

.award-modal img {
    height: 600px;
    width: 100%;
    object-fit: contain;
}


.award-modal .modal-content {
    background-color: transparent;
    border: none;
    position: relative;
}

@media (max-width:568px) {
    .award-modal img {
        height: auto;
    }

    .award-modal .modal-header {
        top: 14px;
        right: 30px;
    }
}