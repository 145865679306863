/* footer {
    background: var(--theme-color);
    color: var(--white-color);
} */
.main-footer {
  width: 100%;
  height: 250px;
  position: relative;
}
.main-footer > img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: -1;
  object-position: bottom;
}
.footer-bottom {
  /* color: var(--theme-color); */
  /* background: var(--white-color); */
}
.footer-bottom a,
.footer-bottom p,
footer a,
footer p {
  color: var(--white-color) !important;
  /* color: var(--theme-color) !important; */
}
.contact-links {
  display: flex;
  justify-content: flex-end;
}
footer .contact-links li > a {
  width: 35px;
  height: 35px;
  padding: 5px;
  background-color: var(--theme-color);
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .contact-links li:last-child > a {
  margin-right: 0;
}
footer .contact-links li svg {
  color: var(--white-color);
}
.payments-icons {
  display: flex;
  gap: 0.7rem;
  flex-wrap: wrap;
}
.payments-icons img {
  width: 50px;
}

.footer-sm-icons {
  /* position: fixed;
    left: 1rem;
    bottom: 1.5rem; */
  display: flex;
  /* z-index: 999; */
  padding: 0;
  margin: 0;
}
.footer-sm-icons a {
  /* width: 35px;
  height: 35px;
  padding: 5px;
  background-color: var(--theme-color); */
  /* border-radius: 50%; */
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-sm-icons a svg {
  font-size: 1.5rem;
  color: var(--white-color);
}
.main-footer .footer-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
}

@media (max-width: 992px) {
  .payments-icons {
    /* justify-content: center; */
    margin: 1rem 0;
  }
  .footer-sm-icons {
    /* justify-content: center; */
  }
  .footer-bottom {
    position: relative !important;
    font-size: 0.9rem;
    /* padding-bottom: 0.9rem !important; */
  }
  footer .main-nav-menu-links > li {
    text-align: left !important;
    padding: 0 !important;
  }
  .main-footer {
    background-color: #0b6275;
    height: auto;
    position: relative;
  }
  .main-footer .footer-icons {
    position: absolute;
    top: 1.5rem;
    left: 0.9rem;
    right: 0.9rem;
    display: flex;
    justify-content: space-between;
  }
  .main-footer .footer-icons img{
    width: 50px;
  }
  .main-footer > img {
    height: auto;
    bottom: 0;
    position: absolute;
    top: inherit;
    z-index: 0;
  }
  .mobile-bgcolor {
    margin-top: 0.5rem !important;
    padding: 0.5rem 0 !important;
    background: #093d4a;
  }
}
.footer-heading {
  color: var(--white-color) !important;
  font-size: 1.5rem;
  font-weight: 500;
}

.footer-contact a {
  color: var(--white-color) !important;
  text-decoration: none !important;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
.footer-copy-right {
  font-size: 1rem;
  font-weight: 500;
  color: var(--white-color);
}
.footer-img img {
  width: 150px;
  margin-bottom: 20px;
  background: var(--white-color) !important;
  padding: 10px;
}
