.special-offer {
    position: fixed;
    width: 100%;
    /* left: 94.6%; */
    /* bottom: 70px; */
    left: 65px;
    bottom: 130px;
    height: 20px;
    font-size: 2.5rem;
    z-index: 1;
    cursor: pointer;
    transition: transform 400ms ease;
}


.special-offer {
    width: 50px !important;
    height: 50px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

}

.special-offer:after {
    position: absolute;
    z-index: -1;
    content: "";
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(ellipse at center,
            rgba(0, 0, 0, 0.15) 0%,
            rgba(0, 0, 0, 0) 80%);
}


.special-offer img {
    height: 150px;
}

@media (max-width: 1200px) {
    .special-offer {
        left: 92% !important;
    }
}

@media (max-width: 568px) {
    /* .special-offer {
        left: 82% !important;
    } */

    .special-offer {
        position: fixed;
        width: 100%;
        /* left: 94.6%; */
        /* bottom: 70px; */
        left: 45px !important;
        bottom: 135px !important;
        height: 20px;
        font-size: 2.5rem;
        z-index: 9;
        cursor: pointer;
        transition: transform 400ms ease;
    }

    .special-offer img {
        height: 130px;
    }
}